import { useState, useEffect } from "react";
import { Form, Dropdown } from "react-bootstrap";
import ReactECharts from "./Charts";
import { proxyObject } from "../../types/response";
import municipalities from "./constants/munis";
import { DataFilterType } from "../../types/props";
import { EChartsOption } from "echarts-for-react";

type MunicipalityData = {
  [muni: string]: (number | Date)[][];
};

export default function NumListingsByMonth({
  chartData,
  filters,
  defaultMuni = "",
}: {
  chartData: proxyObject[];
  filters?: DataFilterType;
  defaultMuni: string;
}) {
  const [muniData, setMuniData] = useState<MunicipalityData>({});
  const [selectedMunis, setSelectedMunis] = useState<string[]>(defaultMuni !== "" ? [defaultMuni] : municipalities);

  useEffect(() => {
    // If the queried muni is not selected, select it
    const queriedMunis = new Set(chartData.map((listing) => listing.data.muni));
    const commonMunis = selectedMunis.filter((muni) => queriedMunis.has(muni.toUpperCase()));

    if (commonMunis.length == 0) {
      setSelectedMunis(Array.from(queriedMunis).map((upperMuni) => upperMuni[0] + upperMuni.slice(1).toLowerCase()));
    } else {
      setSelectedMunis(commonMunis);
    }
  }, [chartData]);

  const tempMuniSeries: MunicipalityData = {};

  useEffect(() => {
    // Initialize empty arrays for each municipality
    municipalities.forEach((muni) => {
      tempMuniSeries[muni.toLowerCase()] = [];
    });

    // Group data by municipality
    chartData.forEach((element) => {
      const muni = String(element.data.muni).toLowerCase();
      if (muni in tempMuniSeries) {
        tempMuniSeries[muni].push([element.data.postDatetime, element.data.ask]);
      }
    });

    // Process data for each municipality
    const processedData: MunicipalityData = {};
    Object.entries(tempMuniSeries).forEach(([muni, data]) => {
      processedData[muni] = groupMedianByMonthYear(data).sort((a, b) => {
        const aDate: Date = new Date(a[0]);
        const bDate: Date = new Date(b[0]);
        return aDate.getTime() - bDate.getTime();
      });
    });

    setMuniData(processedData);
  }, [chartData, filters]);

  function groupMedianByMonthYear(series: (number | Date)[][]) {
    const tempSeriesMap = {};
    series.forEach((element: (number | Date)[]) => {
      const elementDate: Date = new Date(element[0]);
      const elementValue: number = Number(element[1]);
      const year = elementDate.getFullYear();
      const month = elementDate.getMonth();

      if (tempSeriesMap[year] === undefined) {
        tempSeriesMap[year] = {};
      }
      if (tempSeriesMap[year][month] === undefined) {
        tempSeriesMap[year][month] = [];
      }
      tempSeriesMap[year][month].push(elementValue);
    });

    const resultArray: (number | Date)[][] = [];
    Object.keys(tempSeriesMap).forEach((year) => {
      Object.keys(tempSeriesMap[year]).forEach((month) => {
        resultArray.push([new Date(Number(year), Number(month)), tempSeriesMap[year][month].length]);
      });
    });

    return resultArray;
  }

  function handleCheck(muni: string) {
    setSelectedMunis((prev) => {
      if (prev.includes(muni)) {
        return prev.filter((m) => m !== muni);
      }
      return [...prev, muni];
    });
  }

  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },
    title: {
      left: "center",
      text: "Number of Listings by Month",
    },
    legend: {
      top: 25,
      data: municipalities,
      selected: Object.fromEntries(municipalities.map((muni) => [muni, selectedMunis.includes(muni)])),
      selectedMode: false,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "time",
      boundaryGap: false,
    },
    yAxis: {
      type: "value",
    },
    series: municipalities.map((muni) => ({
      type: "line",
      name: muni,
      data: muniData[muni.toLowerCase()] || [],
      showSymbol: true,
    })),
  };

  // Check if any selected municipality has data
  const hasData = selectedMunis.some((muni) => {
    const data = muniData[muni.toLowerCase()];
    return data && data.length > 0;
  });

  return (
    <div style={{ minHeight: "400px", position: "relative" }}>
      {hasData ? (
        <>
          <ReactECharts option={option} />
          <Dropdown style={{ top: "-1rem" }}>
            <Dropdown.Toggle id="dropdown-basic">Choose Municipalities</Dropdown.Toggle>
            <Dropdown.Menu>
              <Form>
                {municipalities.map((muni) => (
                  <Dropdown.Item key={muni} className="mb-3" id={muni} onClick={() => handleCheck(muni)} active={selectedMunis.includes(muni)}>
                    {muni}
                  </Dropdown.Item>
                ))}
              </Form>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "#666",
          }}
        >
          <h4>No data available to display</h4>
          <p>Please select different filters or try again later.</p>
        </div>
      )}
    </div>
  );
}
