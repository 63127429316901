import { useState, useContext, useCallback, useEffect } from "react";

import { styled } from "styled-components";
import { useNavigate } from "react-router";

import Table from "react-bootstrap/Table";

import { elementsArray } from "../../types/props";
import { memberObject, memberResponse } from "../../types/response";
import municipalities from "../components/constants/munis";

import { appContext } from "../context/appContext";

const MembersPageContainer = styled.div`
  height: calc(100vh - 5em);
  background-color: #fbfffe;
  padding-bottom: 2em;
`;

const Header = styled.h2`
  width: 100vw;
  padding-top: 1em;
  padding-bottom: 0.5em;
  font-family: sans-serif;
  font-weight: bold;

  text-align: center;
`;

const ErrorMessage = styled.div`
  align-self: center;
`;

const TableContainer = styled.main`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: stretch;
  background-color: #fbfffe;
  justify-content: center;
`;

const TableScrollContainer = styled.div`
  flex: 1;
  max-height: 80%;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
`;

const MembersTable = styled(Table)`
  width: 50%;
  margin: 1em;
  border-collapse: separate;
  border-spacing: 0px;
`;

const TableHeader = styled.thead``;

const TableHeaderRow = styled.tr``;

const TableRows = styled.tbody``;

const MembersListItem = styled.tr`
  background-color: #fbfffe;
  margin-bottom: 0.65rem;
  &:hover {
    background-color: #ddf4ff;
  }
`;

const MembersListHeader = styled.th`
  font-weight: bold;
  padding: 1rem;

  /* Using bg color from bootstrap's Card "Cap" */
  background-color: #f8f8f8 !important;
  border: 1px solid #dfe2e5 !important;

  /* Sticky header */
  position: sticky;
  top: 0;
`;

const MembersListCell = styled.td`
  padding: 1rem;
  border-width: 1px !important;
`;

const MemberMailto = styled.a`
  text-decoration: none;
  color: #3e54d4;
`;

const townSet = new Set(["Arlington"]);
const citySet = new Set(municipalities.filter((m) => !townSet.has(m)));

export default function Members() {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);
  const navigate = useNavigate();

  const [muni, setMuni] = useState("Municipality");
  const [members, setMembers] = useState<memberObject[]>([]);

  useEffect(() => {
    if (!loginStatus) {
      navigate("/login");
    }
  }, [loginStatus]);

  useEffect(() => {
    const fetchData = async () => {
      await fetch("/api/members", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data !== undefined) {
            setMembers(data.membersList);
          }
        })
        .then((err) => {
          console.error(err);
        });
    };

    fetchData();
  }, []);

  const createTableRows = () => {
    const rows: elementsArray = [];

    const byMuni = members.reduce((acc, member) => {
      const key = member.muni;
      const curGroup = acc[key] ?? [];
      return { ...acc, [key]: [...curGroup, member] };
    }, {});

    let sorted = [] as memberObject[];
    Object.keys(byMuni)
      .sort((a, b) => (a === "" ? 1 : a.localeCompare(b)))
      .forEach((k) => {
        sorted = sorted.concat(byMuni[k].sort((a, b) => a.muni.localeCompare(b.muni)));
      });

    const formatMuni = (member) => {
      if (member.contact.endsWith("mapc.org")) {
        return "MAPC";
      } else if (townSet.has(member.muni)) {
        return `Town of ${member.muni}`;
      } else if (citySet.has(member.muni)) {
        return `City of ${member.muni}`;
      } else {
        return member.muni;
      }
    };

    sorted.forEach((member) => {
      rows.push(
        <MembersListItem>
          <MembersListCell>{member.name}</MembersListCell>
          <MembersListCell>{formatMuni(member)}</MembersListCell>
          <MembersListCell>
            <MemberMailto href={`mailto:${member.contact}`}>{member.contact}</MemberMailto>
          </MembersListCell>
        </MembersListItem>
      );
    });

    return rows;
  };

  return (
    <MembersPageContainer>
      <Header>Members</Header>
      <TableContainer>
        {loginStatus && members.length > 0 && (
          <TableScrollContainer>
            <MembersTable bordered hover>
              <TableHeader>
                <TableHeaderRow>
                  <MembersListHeader>Name</MembersListHeader>
                  <MembersListHeader>Organization</MembersListHeader>
                  <MembersListHeader>Contact</MembersListHeader>
                </TableHeaderRow>
              </TableHeader>
              <TableRows>{createTableRows()}</TableRows>
            </MembersTable>
          </TableScrollContainer>
        )}
        {loginStatus && members.length == 0 && <ErrorMessage>Unable to load member list.</ErrorMessage>}
      </TableContainer>
    </MembersPageContainer>
  );
}
