import { useState, useEffect } from "react";
import { Form, Dropdown } from "react-bootstrap";
import ReactECharts from "./Charts";
import { proxyObject } from "../../types/response";
import municipalities from "./constants/munis";
import { DataFilterType } from "../../types/props";
import { EChartsOption } from "echarts-for-react";



type MunicipalityData = {
  [muniName: string]: (number | Date)[][];
};

export default function MedianAskMonthChart({
  chartData,
  filters,
  defaultMuni = "",
}: {
  chartData: proxyObject[];
  filters?: DataFilterType;
  defaultMuni: string;
}) {
  const [muniData, setMuniData] = useState<MunicipalityData>({});
  const [selectedMunis, setSelectedMunis] = useState<string[]>(
    defaultMuni !== "" ? [defaultMuni] : municipalities
  );

  // Reset selected munis when defaultMuni changes
  useEffect(() => {
    // If the queried muni is not selected, select it
    const queriedMunis = new Set(chartData.map((listing) => listing.data.muni));
    const commonMunis = selectedMunis.filter((muni) => queriedMunis.has(muni.toUpperCase()));

    if (commonMunis.length == 0) {
      setSelectedMunis(Array.from(queriedMunis).map((upperMuni) => upperMuni[0] + upperMuni.slice(1).toLowerCase()));
    } else {
      setSelectedMunis(commonMunis);
    }
  }, [chartData]);

  // Process data whenever chartData changes
  useEffect(() => {
    // Reset muniData when new data comes in
    setMuniData({});

    const tempMuniSeries: MunicipalityData = {};
    
    // Initialize empty arrays for each municipality
    municipalities.forEach(muni => {
      tempMuniSeries[muni.toLowerCase()] = [];
    });

    // Group data by municipality
    chartData.forEach(element => {
      const muni = String(element.data.muni).toLowerCase();
      if (muni in tempMuniSeries) {
        tempMuniSeries[muni].push([element.data.postDatetime, element.data.ask]);
      }
    });

    // Process data for each municipality
    const processedData: MunicipalityData = {};
    Object.entries(tempMuniSeries).forEach(([muni, data]) => {
      processedData[muni] = groupMedianByMonthYear(data).sort((a, b) => {
        const aDate: Date = new Date(a[0]);
        const bDate: Date = new Date(b[0]);
        return aDate.getTime() - bDate.getTime();
      });
    });

    setMuniData(processedData);
  }, [chartData]); // Remove filters from dependency array if it's not needed

  function median(numbers: number[]) {
    const data = Array.from(numbers).sort((a: number, b: number) => a - b);
    const middle = Math.floor(data.length / 2);
    return data.length % 2 === 0
      ? (data[middle - 1] + data[middle]) / 2
      : data[middle];
  }

  function groupMedianByMonthYear(series: (number | Date)[][]) {
    const tempSeriesMap: { [key: string]: { [key: string]: number[] } } = {};
    
    series.forEach((element: (number | Date)[]) => {
      const elementDate: Date = new Date(element[0]);
      const elementValue: number = Number(element[1]);
      const year = elementDate.getFullYear();
      const month = elementDate.getMonth();

      if (!tempSeriesMap[year]) {
        tempSeriesMap[year] = {};
      }
      if (!tempSeriesMap[year][month]) {
        tempSeriesMap[year][month] = [];
      }
      tempSeriesMap[year][month].push(elementValue);
    });

    return Object.entries(tempSeriesMap).flatMap(([year, months]) =>
      Object.entries(months).map(([month, values]) => [
        new Date(Number(year), Number(month)),
        median(values),
      ])
    );
  }

  function handleCheck(select: string) {
    const tempSelect = [...selectedMunis];
    if (tempSelect.includes(select)) {
      tempSelect.splice(tempSelect.indexOf(select), 1);
    } else {
      tempSelect.push(select);
    }
    setSelectedMunis(tempSelect);
  }

  const legendObject = Object.fromEntries(
    municipalities.map(element => [element, selectedMunis.includes(element)])
  );

  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },
    title: {
      left: "center",
      text: "Median Ask by Month",
    },
    legend: {
      top: 25,
      data: municipalities,
      selected: legendObject,
      selectedMode: false,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "time",
      boundaryGap: false,
    },
    yAxis: {
      type: "value",
    },
    series: municipalities.map(muni => ({
      type: "line",
      name: muni,
      data: muniData[muni.toLowerCase()] || [],
      showSymbol: true,
    })),
  };

  // Add a check for empty data
  const hasData = Object.values(muniData).some(data => data.length > 0);

  return (
    <div style={{ minHeight: "400px", position: "relative" }}>
      {hasData ? (
        <>
          <ReactECharts option={option} />
          <Dropdown style={{ top: "-1rem" }}>
            <Dropdown.Toggle id="dropdown-basic">Choose Municipalities</Dropdown.Toggle>
            <Dropdown.Menu>
              <Form>
                {municipalities.map((muni) => (
                  <Dropdown.Item
                    key={muni}
                    className="mb-3"
                    id={muni}
                    onClick={() => handleCheck(muni)}
                    active={selectedMunis.includes(muni)}
                  >
                    {muni}
                  </Dropdown.Item>
                ))}
              </Form>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <div 
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "#666"
          }}
        >
          <h4>No data available to display</h4>
          <p>Please select different filters or try again later.</p>
        </div>
      )}
    </div>
  );
}
