import { useEffect, useState, useContext } from "react";

import { useNavigate } from "react-router";

import { styled } from "styled-components";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { UpdateType, elementsArray, UpdatesArray } from "../../types/props";

import { UserData } from "../../types/auth";
import { postObject, postResponse } from "../../types/response";
import { appContext } from "../context/appContext";

import { Card, Image, Col, Row, Pagination, Spinner } from "react-bootstrap";

const AnalysesBody = styled.div`
  width: 100vw;
  height: calc(100vh - 5em);
  background-color: #fbfffe;
  color: #635c7b;
  display: flex;
  padding: 2rem 5rem;
  flex-direction: column;
  justify-content: center;
`;

const AnalysesPager = styled.div`
  max-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AnalysisCard = styled(Card)`
  max-height: 90%;
  flex: 4;
`;

const AnalysisCardBody = styled(Card.Body)`
  overflow-y: scroll;
`;

const AnalysisPagination = styled(Pagination)`
  flex: 1;
  padding: 1rem;
  max-height: 10%;
`;

export const Analyses = () => {
  const { loginStatus, userSettings } = useContext(appContext);
  const [page, setPage] = useState(0);

  const [posts, setPosts] = useState<postObject[]>([]);
  const [fetchedPosts, setFetchedPosts] = useState<boolean>(false);

  useEffect(() => {
    if (!fetchedPosts && userSettings) {
      const fetchData = async () => {
        await fetch(`/api/posts/${page + 1}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => response.json())
          .then((data: postResponse) => {
            if (data !== undefined) {
              if (userSettings && userSettings.email.endsWith("@mapc.org")) {
                setPosts(data["posts"].filter((p) => p.Status === "Published" || p.Status === "Preview"));
              } else {
                setPosts(data["posts"].filter((p) => p.Status === "Published"));
              }
            }
          });
      };

      fetchData();
      setFetchedPosts(true);
    }
  }, [fetchedPosts, userSettings]);

  const generateCard = () => {
    if (posts.length <= 0 || page >= posts.length) {
      return;
    }
    const post = posts[page];
    return (
      <AnalysisCard>
        <Card.Header as="h5">{page === 0 ? "Latest Analysis" : "Previous Analyses"}</Card.Header>
        <AnalysisCardBody>
          <Card.Title>
            <Markdown remarkPlugins={[remarkGfm]}>{post.Title}</Markdown>
          </Card.Title>
          <Card.Text>
            <Markdown remarkPlugins={[remarkGfm]}>{post.Body}</Markdown>
          </Card.Text>
          <Row xs={2}>
            <Col xs={4}>
              {post.Figure1 !== undefined && (
                <a href={post.Figure1[0].url} rel="noreferrer" target="_blank">
                  <Image src={post.Figure1[0].url} fluid />
                </a>
              )}
            </Col>
            <Col xs={4}>
              {post.Figure2 !== undefined && (
                <a href={post.Figure2[0].url} rel="noreferrer" target="_blank">
                  <Image src={post.Figure2[0].url} fluid />
                </a>
              )}
            </Col>
          </Row>
          <Row xs={2}>
            <Col xs={4}>
              {post.Figure3 !== undefined && (
                <a href={post.Figure3[0].url} rel="noreferrer" target="_blank">
                  <Image src={post.Figure3[0].url} fluid />
                </a>
              )}
            </Col>
            <Col xs={4}>
              {post.Figure4 !== undefined && (
                <a href={post.Figure4[0].url} rel="noreferrer" target="_blank">
                  <Image src={post.Figure4[0].url} fluid />
                </a>
              )}
            </Col>
          </Row>

          {/* <Button variant="primary">Go somewhere</Button> */}
        </AnalysisCardBody>
        <Card.Footer className="text-muted">{post.Date} </Card.Footer>
      </AnalysisCard>
    );
  };

  return (
    <AnalysesBody>
      {loginStatus && (
        <AnalysesPager>
          {posts.length === 0 && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {posts.length !== 0 && (
            <>
              {generateCard()}
              <AnalysisPagination style={{ justifyContent: "center" }}>
                {posts.length > 1 && (
                  <>
                    <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page == 0} />
                    {posts.map((post, i) => (
                      <Pagination.Item
                        key={i}
                        active={i === page}
                        onClick={() => {
                          setPage(i);
                        }}
                      >
                        {i + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setPage(page + 1)} disabled={page == posts.length - 1} />
                  </>
                )}
              </AnalysisPagination>
            </>
          )}
        </AnalysesPager>
      )}
      {!loginStatus && <strong>Please log in to view data analyses.</strong>}
    </AnalysesBody>
  );
};

export default Analyses;
