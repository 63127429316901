import { useState, useContext, useCallback, useEffect, FC } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { styled } from "styled-components";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";

import { appContext } from "../context/appContext";
import { UserData } from "../../types/auth";
import {
  ModalContainer,
  ModalForm,
  ModalLink,
  ModalControl,
  ModalControlFeedback,
  ModalGroup,
  ModalLabel,
  ModalSubmitButton,
  ModalSubmissionStatus,
} from "../containers/ModalContainer";

export const Registration = () => {
  const { loginStatus, setLoginStatus, userSettings, setUserSettings } = useContext(appContext);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required()
      .trim()
      .label("E-mail")
      .matches(
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@(cambridgema.gov|somervillema.gov|boston.gov|quincyma.gov|town.arlington.ma.us|mapc.org|medford-ma.gov)$/,
        "E-mail address must be a valid municipal e-mail address."
      ),
    password: yup.string().required().label("Password"),
    /*
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/,
        "Password must be at least 7 characters long, and must contain a mix of numbers and uppercase, lowercase, and special characters."
      ),
      */
    passwordConfirmation: yup
      .string()
      .required()
      .label("Password confirmation")
      .oneOf([yup.ref("password")], "Passwords must match"),
    name: yup.string().required().label("Name"),
  });

  const initialValues = {
    email: "",
    password: "",
    passwordConfirmation: "",
    name: "",
  };

  useEffect(() => {
    // If we're already logged in, go to the homepage
    if (loginStatus) {
      navigate("/");
    }
  });

  const submitRegistration = async (values, actions) => {
    const response = await fetch("/api/register", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...values }),
    });

    const data = await response.json();

    if (data != null && data.ok && data.userSession != null) {
      setUserSettings(data.userSession);
      setLoginStatus(true);
    }
    setSubmitted(true);
  };

  return (
    <ModalContainer title="Registration">
      <Formik validateOnBlur validationSchema={schema} onSubmit={submitRegistration} initialValues={initialValues}>
        {({ handleSubmit, handleBlur, handleChange, values, touched, errors, isSubmitting }) => (
          <ModalForm noValidate onSubmit={handleSubmit}>
            <ModalGroup>
              <ModalLabel>E-mail address</ModalLabel>
              <ModalControl
                required
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email}
                placeholder="Enter municipal e-mail address"
              />
              <ModalControlFeedback type="invalid">
                <ErrorMessage name="email" />
              </ModalControlFeedback>
            </ModalGroup>

            <ModalGroup>
              <ModalLabel>Password</ModalLabel>
              <ModalControl
                required
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.password && !!errors.password}
                placeholder="Create a strong password"
              />
              <ModalControlFeedback type="invalid">
                <ErrorMessage name="password" />
              </ModalControlFeedback>
              <ModalControl
                required
                type="password"
                name="passwordConfirmation"
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
                placeholder="Confirm password"
              />
              <ModalControlFeedback type="invalid">
                <ErrorMessage name="passwordConfirmation" />
              </ModalControlFeedback>
            </ModalGroup>
            <ModalGroup>
              <ModalLabel>Name</ModalLabel>
              <ModalControl
                required
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.name && !!errors.name}
                placeholder="Enter your full name"
              />
              <ModalControlFeedback type="invalid">
                <ErrorMessage name="name" />
              </ModalControlFeedback>
            </ModalGroup>
            {!isSubmitting && submitted && !loginStatus && (
              <ModalSubmissionStatus>
                There was an unexpected issue when trying to register your account. Please try again later, or contact us at{" "}
                <a href="mailto:digitalservices@mapc.org">digitalservices@mapc.org</a>.
              </ModalSubmissionStatus>
            )}
            <ModalSubmitButton isSubmitting={isSubmitting} label="Register" />
            <ModalLink to="/login">Already registered?</ModalLink>
          </ModalForm>
        )}
      </Formik>
    </ModalContainer>
  );
};

export default Registration;
