import { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import React from "react";

import { Form, Dropdown } from "react-bootstrap";

import ReactECharts from "./Charts";
import { proxyObject } from "../../types/response";

import municipalities from "./constants/munis";
import { DataFilterType } from "../../types/props";

import { EChartsOption } from "echarts-for-react";
import type { LineSeriesOption } from "echarts/charts";

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const NoDataContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: "#666";
`;

export default function MedianBedMonthChart({
  chartData,
  filters,
  defaultMuni = "",
}: {
  chartData: proxyObject[];
  filters?: DataFilterType;
  defaultMuni: string;
}) {
  // [Date: {numRooms: median}]
  const [data, setData] = useState<(object | Date)[][]>([]);

  const [selectedMuni, setSelectedMuni] = useState<string>(defaultMuni);

  useEffect(() => {
    // If the queried muni is not selected, select it
    const queriedMunis = new Set(chartData.map((listing) => listing.data.muni));

    if (queriedMunis.size === 1 || (queriedMunis.size >= 1 && defaultMuni === "")) {
      const queriedMuni = Array.from(queriedMunis)[0];
      setSelectedMuni(queriedMuni[0] + queriedMuni.slice(1).toLowerCase());
    }
  }, [chartData]);

  useEffect(() => {
    // add data to chart data array
    let tempSeries: (number | Date)[][] = [];

    const askArray: number[] = chartData.map((element) => {
      // if the data is member of muni, push to data series
      if (element.data.muni.toLowerCase() === selectedMuni.toLowerCase()) {
        tempSeries.push([element.data.postDatetime, element.data.ask, element.data.bedrooms]);
      }
      return element.data.ask;
    });

    function median(numbers: number[]) {
      const data = Array.from(numbers).sort((a: number, b: number) => a - b);

      const middle = Math.floor(data.length / 2);

      if (data.length % 2 === 0) {
        return (data[middle - 1] + data[middle]) / 2;
      }

      return data[middle];
    }

    function groupMedianByBedroomByMonthYear(series: (number | Date)[][]) {
      const tempSeriesMap = {};
      series.forEach((element: (number | Date)[]) => {
        const elementDate: Date = new Date(element[0]);
        const elementValue: number = Number(element[1]);
        const elementBedrooms: number = Number(element[2]);
        const year = elementDate.getFullYear();
        const month = elementDate.getMonth();

        if (tempSeriesMap[year] === undefined) {
          tempSeriesMap[year] = {};
          if (tempSeriesMap[year][month] === undefined) {
            tempSeriesMap[year][month] = {};
            if (tempSeriesMap[year][month][elementBedrooms] === undefined) {
              tempSeriesMap[year][month][elementBedrooms] = [elementValue];
            } else {
              tempSeriesMap[year][month][elementBedrooms].push(elementValue);
            }
          }
        } else if (tempSeriesMap[year][month] === undefined) {
          tempSeriesMap[year][month] = {};
          if (tempSeriesMap[year][month][elementBedrooms] === undefined) {
            tempSeriesMap[year][month][elementBedrooms] = [elementValue];
          } else {
            tempSeriesMap[year][month][elementBedrooms].push(elementValue);
          }
        } else if (tempSeriesMap[year][month][elementBedrooms] === undefined) {
          tempSeriesMap[year][month][elementBedrooms] = [elementValue];
        } else {
          tempSeriesMap[year][month][elementBedrooms].push(elementValue);
        }
      });

      const resultArray: (object | Date)[][] = [];
      // calculate median from grouped values
      Object.keys(tempSeriesMap).forEach((year) => {
        Object.keys(tempSeriesMap[year]).forEach((month) => {
          const tempMap = {};
          for (let numRooms = 0; numRooms < 5; numRooms++) {
            // fill up object with the medians unless theres no matching bedroom counts then mark it 0
            if (tempSeriesMap[year][month][numRooms] === undefined || (filters?.numRooms !== "" && String(numRooms) !== filters?.numRooms)) {
              tempMap[numRooms] = null;
            } else {
              tempMap[numRooms] = median(tempSeriesMap[year][month][numRooms]);
            }
          }
          resultArray.push([new Date(Number(year), Number(month)), tempMap]);
        });
      });

      return resultArray;
    }

    const resultMap: (object | Date)[][] = groupMedianByBedroomByMonthYear(tempSeries);

    // set series data
    setData(
      resultMap.sort((a, b) => {
        return new Date(a[0] as Date).getTime() - new Date(b[0] as Date).getTime();
      })
    );
  }, [chartData, filters, selectedMuni]);

  const series: LineSeriesOption[] = [];

  // create series for numrooms up to 5
  for (let i = 0; i < 5; i++) {
    series.push({
      type: "line",
      name: String(i),
      data: [],
      showSymbol: true,
    });
  }

  data.forEach((element) => {
    for (let i = 0; i < 5; i++) {
      series[i].data?.push([new Date(element[0] as Date), element[1][i]]);
    }
  });

  const option: EChartsOption = {
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },
    title: {
      left: "center",
      text: `${selectedMuni} Median Ask by Bedroom by Month`,
    },
    legend: {
      top: 25,
      data: ["0", "1", "2", "3", "4", "5", "6"],
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "time",
      boundaryGap: false,
    },
    yAxis: {
      type: "value",
      // boundaryGap: [0, "100%"],
    },
    series: series,
  };

  // Add check for empty data
  const hasData = data.length > 0 && Object.values(data[0][1]).some((value) => value !== null);

  return (
    <ChartContainer>
      {hasData ? (
        <>
          <ReactECharts option={option} />
        </>
      ) : (
        <NoDataContainer>
          <h4>No data available to display</h4>
          <p>Please select different filters or try again later.</p>
        </NoDataContainer>
      )}
      <Dropdown style={{ top: "-1rem" }}>
        <Dropdown.Toggle id="dropdown-basic">Choose Municipality</Dropdown.Toggle>
        <Dropdown.Menu>
          <Form>
            {municipalities.map((muni) => (
              <Dropdown.Item
                key={`${muni}`}
                className="mb-3"
                id={`${muni}`}
                onClick={() => {
                  setSelectedMuni(muni);
                }}
                active={muni === selectedMuni}
              >
                {muni}
              </Dropdown.Item>
            ))}
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </ChartContainer>
  );
}
