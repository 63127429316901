import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";

import App from "./App";

import AboutPage from "./pages/About";
import Data from "./pages/data";
import Documentation from "./pages/documentation";
// import Settings from "./pages/settings";
import Members from "./pages/members";
import Login from "./pages/login";
import Registration from "./pages/registration";
import Analyses from "./pages/Analyses";
import PasswordResetRequest from "./pages/password_reset_request";
import PasswordReset from "./pages/password_reset";
import VerifyEmail from "./pages/verify_email";

import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
  {
    element: <App />,
    // errorElement: <ErrorPage />,

    children: [
      {
        path: "/",
        element: <AboutPage />,
      },
      {
        path: "/analyses",
        element: <Analyses />,
      },
      {
        path: "/data",
        element: <Data />,
      },
      {
        path: "/documentation",
        element: <Documentation />,
      },
      /*
      {
        path: "/settings",
        element: <Settings />,
      },
      */
      {
        path: "/members",
        element: <Members />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Registration />,
      },
      {
        path: "/password-reset-request",
        element: <PasswordResetRequest />,
      },
      {
        path: "/password-reset",
        element: <PasswordReset />,
      },
      {
        path: "/verify-email",
        element: <VerifyEmail />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
